import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm } from "../utils/typography";

export default ({ data }) => {
    const posts = data.allMarkdownRemark.edges;
    return (
        <Layout>
            <SEO />
            <main>
                {posts.map(({ node }) => (
                    <article className="list-post" key={node.fields.slug}>
                        <header style={{ marginBottom: rhythm(1 / 2) }}>
                            <h3 className="list-post-title">
                                <Link
                                    className="post-link"
                                    to={node.fields.slug}
                                    aria-label={`View ${node.frontmatter.title} article`}
                                >
                                    {node.frontmatter.title}
                                </Link>
                            </h3>
                            <small className="blog-meta">{node.frontmatter.date} • ⏱{' '} {node.timeToRead} min
                            read</small>
                        </header>
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    node.frontmatter.description ||
                                    node.excerpt,
                            }}
                        />
                        <Link className="button-primary" to={node.fields.slug} aria-label={`View ${node.frontmatter.title} article`}>Read →</Link>
                    </article>
                ))}
            </main>
        </Layout>
    );
};

export const pageQuery = graphql`
    query  blogListQuery ($skip: Int!, $limit: Int!) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    excerpt
                    timeToRead
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "DD MMMM, YYYY")
                        title
                        description
                    }
                }
            }
        }
    }
`;
